import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import './Recipe.scss';

import Code from './Code';

function RecipeComponent() {
    const { name, task } = useParams();
    const [currentStep, setCurrentStep] = useState(0);
    const [checklistState, setChecklistState] = useState({});
    const [selectedTask, setSelectedTask] = useState(null);
    const [showPopover, setShowPopover] = useState(false);
    const [hoveredItem, setHoveredItem] = useState(null);
    const [contentRecipe, setContentRecipe] = useState(null);
    const [imagePositions, setImagePositions] = useState({});

    const getRandomPosition = (index, totalImages) => {
        const containerWidth = window.innerWidth;
        const containerHeight = window.innerHeight;
        const imageSize = 175;
        const maxOverlap = 0.2; // 50% overlap allowed

        const effectiveWidth = containerWidth - imageSize * (1 - maxOverlap);
        const effectiveHeight = containerHeight - imageSize * (1 - maxOverlap);

        const columns = Math.ceil(Math.sqrt(totalImages));
        const rows = Math.ceil(totalImages / columns);

        const columnWidth = effectiveWidth / columns;
        const rowHeight = effectiveHeight / rows;

        const col = index % columns;
        const row = Math.floor(index / columns);

        const x = (col * columnWidth + Math.random() * (columnWidth - imageSize * (1 - maxOverlap))) / containerWidth * 100;
        const y = (row * rowHeight + Math.random() * (rowHeight - imageSize * (1 - maxOverlap))) / containerHeight * 100;
        const rotation = Math.random() * 30 - 15; // Random rotation between -15 and 15 degrees

        return { x, y, rotation };
    };

    useEffect(() => {
        fetch('/Content/content-recipe.json')
            .then(response => response.json())
            .then(data => {
                setContentRecipe(data);
                // Find the correct task
                const foundTask = data[name]?.tasks.find((t, index) => index === parseInt(task) - 1);
                setSelectedTask(foundTask);

                // Initialize checklist state for all steps
                if (foundTask) {
                    const initialState = {};
                    foundTask.steps.forEach((step, stepIndex) => {
                        if (step.checklist && step.checklist.main) {
                            initialState[stepIndex] = {
                                main: step.checklist.main.map(() => false),
                                extra: step.checklist.extra ? step.checklist.extra.map(() => false) : []
                            };
                        }
                    });
                    setChecklistState(initialState);
                }

                // Generate random positions for images
                if (foundTask) {
                    const positions = {};
                    foundTask.steps.forEach((step, stepIndex) => {
                        if (step.checklist) {
                            const images = [...(step.checklist.main || []), ...(step.checklist.extra || [])].filter(item => item.image);
                            images.forEach((item, itemIndex) => {
                                positions[`${stepIndex}-${itemIndex}`] = getRandomPosition(itemIndex, images.length);
                            });
                        }
                    });
                    setImagePositions(positions);
                }
            })
            .catch(error => console.error('Error loading content-recipe.json:', error));
    }, [name, task]);

    if (!selectedTask) {
        return <div>Loading...</div>;
    }

    const totalSteps = selectedTask.steps.length;

    const goToNextStep = () => {
        if (currentStep < totalSteps - 1) {
            setCurrentStep(currentStep + 1);
        }
    };

    const goToPreviousStep = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    const handleCheckboxChange = (listType, index) => {
        setChecklistState(prevState => ({
            ...prevState,
            [currentStep]: {
                ...prevState[currentStep],
                [listType]: prevState[currentStep][listType].map((item, i) =>
                    i === index ? !item : item
                )
            }
        }));
    };

    const handleImageClick = (listType, index) => {
        handleCheckboxChange(listType, index);
    };

    const togglePopover = () => {
        setShowPopover(!showPopover);
    };

    const closePopover = (e) => {
        if (e.target.classList.contains('popover-overlay')) {
            setShowPopover(false);
        }
    };

    const handleItemHover = (listType, index) => {
        setHoveredItem({ listType, index });
    };

    const handleItemLeave = () => {
        setHoveredItem(null);
    };

    return (
        <div className='recipe-container'>
            <div className='step-navigation'>
                <button onClick={goToPreviousStep} disabled={currentStep === 0}>
                    <img src={'/SVG/Recipe/Arrow_Left.svg'} alt="Arrow Left" />
                </button>
                <span>{currentStep + 1} / {totalSteps}</span>
                <button onClick={goToNextStep} disabled={currentStep === totalSteps - 1}>
                    <img src={'/SVG/Recipe/Arrow_Right.svg'} alt="Arrow Right" />
                </button>
            </div>

            <div>
                {selectedTask.steps[currentStep].code ? (
                    <img onClick={togglePopover} className='mascot' src={'/SVG/Recipe/Mascot_Blue_Q.svg'} style={{ cursor: 'pointer' }} alt="Blue Q Mascot Base" />
                ) : null
                // (
                //     <img className='mascot' style={{opacity: 0}} src={'/SVG/Recipe/Mascot_Blue_Q_base.svg'} alt="Blue Q Mascot" />
                // )
                }
            </div>

            {showPopover && (
                <div className="popover-overlay" onClick={closePopover}>
                    <div className="popover-content">
                        <button className="close-btn" onClick={togglePopover}>X</button>
                        <h3>Hilfe bei Problemen</h3>
                        <h4>Validierung Code funktioniert nicht?</h4>
                        <p>Falls eine bestimmte Zeile in der Fehlermeldung angegeben wird:</p>
                        <ul>
                            <li>sind alle Begriffe richtig geschrieben?</li>
                            <li>fehlen ; am Ende der Codezeile?</li>
                            <li>falls es immer noch nicht klappt, kopiere die Fehlermeldung und gib sie in einem Suchdienst ein und schau, ob du eine Erklärung für die Fehlermeldung findest.</li>
                        </ul>
                        <h4>Hochladen des Codes funktioniert nicht?</h4>
                        <ul>
                            <li>Ist der Mikrocontroller am Computer angeschlossen?</li>
                            <li>Ist das richtige Board "Arduino Leonardo" eingestellt?</li>
                            <li>Ist der richtige Port (USB-Port) ausgewählt?</li>
                        </ul>
                    </div>
                </div>
            )}

            <div className='container'>
                {selectedTask.steps[currentStep].text && selectedTask.steps[currentStep].text.trim() !== '' && (
                    <div className='content'>
                        <h3>{selectedTask.steps[currentStep].title}</h3>
                        <p dangerouslySetInnerHTML={{ __html: selectedTask.steps[currentStep].text }} />
                    </div>
                )}
                {selectedTask.steps[currentStep].checklist && selectedTask.steps[currentStep].checklist.main && selectedTask.steps[currentStep].checklist.main.length > 0 && (
                    <div>
                        <div className='content'>
                            {!selectedTask.steps[currentStep].text || selectedTask.steps[currentStep].text.trim() === '' ? (
                                <h3>Checklist {currentStep + 1}/{selectedTask.steps.filter(step => step.checklist.main && step.checklist.main.length > 0).length}</h3>
                            ) : null}
                                <div>
                                    <p>{selectedTask.steps[currentStep].checklist.text || "Komponenten aus dem Arduino Kit Basic, die benötigt werden"}</p>
                                    <ul className="checklist-item">
                                        {selectedTask.steps[currentStep].checklist.main.map((item, checkIndex) => (
                                            <li key={checkIndex}
                                                onMouseEnter={() => handleItemHover('main', checkIndex)}
                                                onMouseLeave={handleItemLeave}>
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        checked={checklistState[currentStep]?.main[checkIndex] || false}
                                                        onChange={() => handleCheckboxChange('main', checkIndex)}
                                                    />
                                                    {item.text}
                                                </label>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            {selectedTask.steps[currentStep].checklist.extra && selectedTask.steps[currentStep].checklist.extra.length > 0 ? (
                                <div>
                                    <p>zusätzlich benötigtes Equipment / Material</p>
                                    <ul className="checklist-item">
                                        {selectedTask.steps[currentStep].checklist.extra.map((item, checkIndex) => (
                                            <li key={checkIndex}
                                                onMouseEnter={() => handleItemHover('extra', checkIndex)}
                                                onMouseLeave={handleItemLeave}>
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        checked={checklistState[currentStep]?.extra[checkIndex] || false}
                                                        onChange={() => handleCheckboxChange('extra', checkIndex)}
                                                    />
                                                    {item.text}
                                                </label>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ) : null}
                        </div>

                        <div className="checklist-images">
                            {[...selectedTask.steps[currentStep].checklist.main, ...(selectedTask.steps[currentStep].checklist.extra || [])].map((item, index) => {
                                if (!item.image) return null;
                                const listType = index < selectedTask.steps[currentStep].checklist.main.length ? 'main' : 'extra';
                                const adjustedIndex = listType === 'extra' ? index - selectedTask.steps[currentStep].checklist.main.length : index;
                                const position = imagePositions[`${currentStep}-${index}`] || { x: 0, y: 0, rotation: 0 };
                                const totalImages = [...selectedTask.steps[currentStep].checklist.main, ...(selectedTask.steps[currentStep].checklist.extra || [])].filter(item => item.image).length;
                                return (
                                    <div 
                                        key={`${listType}-${adjustedIndex}`} 
                                        className={`image-container ${totalImages > 7 ? 'many-images' : ''}`}
                                        style={{
                                            left: `${position.x}%`, 
                                            top: `${position.y}%`, 
                                            transform: `rotate(${position.rotation}deg)`
                                        }}
                                    >
                                        <div className="image-wrapper">
                                            <img 
                                                src={item.image} 
                                                alt={item.text} 
                                                className={`
                                                    ${hoveredItem && hoveredItem.listType === listType && hoveredItem.index === adjustedIndex ? 'zoomed' : ''}
                                                    ${checklistState[currentStep]?.[listType][adjustedIndex] ? 'checked' : 'unchecked'}
                                                `}
                                                onClick={() => handleImageClick(listType, adjustedIndex)}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
                {selectedTask.steps[currentStep].code && selectedTask.steps[currentStep].code.trim() !== '' && (
                    <Code filePath={selectedTask.steps[currentStep].code} />
                )}
                {selectedTask.steps[currentStep].image && (
                    <div className='content image'>
                        {Array.isArray(selectedTask.steps[currentStep].image) ? (
                            <div className='multiple-images'>
                                {selectedTask.steps[currentStep].image.map((img, index) => (
                                    <img key={index} src={img} alt={`Step illustration ${index + 1}`} />
                                ))}
                            </div>
                        ) : (
                            <img src={selectedTask.steps[currentStep].image} alt="Step illustration" />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default RecipeComponent;