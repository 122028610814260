import React, { useState, useEffect, useCallback } from 'react';
import '../App.scss';
import './Map.scss';

function spawnCloud(initial = false) {
  const x = initial ? Math.random() * 100 : -15;
  const y = 2 + Math.random() * 33;
  const key = new Date().getTime() + Math.random();
  const speed = 0.1 + Math.random() * 0.2; // Reduced speed range
  const size = 0.8 + speed * 2;
  const delay = initial ? Math.random() * 0.5 : 0;
  const cloud = (
    <img
      key={key}
      className='cloud'
      src={'/SVG/Map/Cloud.svg'}
      style={{ 
        left: `${x}%`, 
        top: `${y}%`,
        transform: `scale(${size})`,
        opacity: 0,
        animation: `fadeIn 1s ease-in forwards ${delay}s, floatCloud ${10/speed}s linear infinite ${delay}s`
      }}
      data-speed={speed}
      data-size={size}
    />
  );
  return cloud;
}

function spawnBuildings() {
  const buildings = [];
  const buildingSources = [
    { src: '/SVG/Map/Building_1.svg', text: "Bibliothek", size: "md" }, 
    { src: '/SVG/Map/Building_2.svg', text: "Tourist Center", size: "sm" }, 
    { src: '/SVG/Map/Building_3.svg', text: "Schule", size: "md" }, 
    { src: '/SVG/Map/Building_4.svg', text: "Fabrik" }, 
    { src: '/SVG/Map/Building_5.svg', text: "Help Center", size: "sm" }, 
    { src: '/SVG/Map/Building_6.svg', text: "Makerspace", size: "md", orientation: "left" }
  ]
  for (let i = 0; i < 6; i++) {
    const building = (
      <div 
        key={i} 
        className={`building-container building-container-${i + 1}`} 
        onClick={() => window.location.href = `/map/${buildingSources[i].text.toLowerCase().replace(/\s/g, '-')}`}
        style={{
          opacity: 0,
          animation: `fadeIn 0.5s ease-in forwards ${i * 0.05}s`
        }}
      >
        <div className={`building-sign ${buildingSources[i].size ? buildingSources[i].size : ''} ${buildingSources[i].orientation ? buildingSources[i].orientation : ''}`}>
          <span className='text'>{buildingSources[i].text}</span>
          <img
            className='svg'
            src='/SVG/Map/Building_Sign.svg'
          />
        </div>
        <img
          className={`building`}
          src={buildingSources[i].src}
          alt={`Building ${i + 1}`}
        />
      </div>
    );
    buildings.push(building);
  }
  return buildings;
}

function MapComponent() {
  const initialClouds = Array.from({ length: 5 }, () => spawnCloud(true));
  const [clouds, setClouds] = useState(initialClouds);
  const [ledStates, setLedStates] = useState(Array(16).fill(false));
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    // Preload LED images
    const ledOffImage = new Image();
    const ledOnImage = new Image();
    ledOffImage.src = '/SVG/Map/Led.svg';
    ledOnImage.src = '/SVG/Map/Led_On.svg';

    Promise.all([
      new Promise(resolve => ledOffImage.onload = resolve),
      new Promise(resolve => ledOnImage.onload = resolve)
    ]).then(() => setImagesLoaded(true));

    // Cloud movement interval
    const cloudInterval = setInterval(() => {
      setClouds((prevClouds) => {
        const newClouds = prevClouds.map((cloud) => {
          const speed = parseFloat(cloud.props['data-speed']);
          const size = parseFloat(cloud.props['data-size']);
          const newLeft = parseFloat(cloud.props.style.left) + speed;
          if (newLeft > 105) {
            const randomDelay = Math.random() * 5000;
            setTimeout(() => {
              setClouds((prevClouds) => {
                return prevClouds.map((c) => (c === cloud ? spawnCloud() : c));
              });
            }, randomDelay);
            return cloud;
          }
          return React.cloneElement(cloud, {
            style: { 
              ...cloud.props.style, 
              left: `${newLeft}%`,
              transform: `scale(${size})`
            },
          });
        });
        return newClouds;
      });
    }, 50); // Increased interval from 50ms to 100ms

    // LED blinking interval
    const ledInterval = setInterval(() => {
      setLedStates(prevStates => 
        prevStates.map(() => Math.random() > 0.7)
      );
    }, Math.random() * 2500 + 1500); // Random interval between 500ms and 1500ms

    return () => {
      clearInterval(cloudInterval);
      clearInterval(ledInterval);
    };
  }, []);

  const spawnLEDs = useCallback(() => {
    const ledCoordinates = [
      { left: '1%', bottom: '90px' },
      { left: '2%', bottom: '-10px' },
      { left: '3%', bottom: '60px' },
      { left: '4%', bottom: '20px' },
      { left: '8%', bottom: '50px' },
      { left: '10%', bottom: '10px' },
      { left: '55%', bottom: '9px' },
      { left: '30%', bottom: '10px' },
      { left: '35%', bottom: '5px' },
      { left: '45%', bottom: '20px' },
      { left: '40%', bottom: '50px' },
      { left: '44%', bottom: '80px' },
      { left: '60%', bottom: '25px' },
      { left: '80%', bottom: '0px' },
      { left: '88%', bottom: '10px' },
      { left: '95%', bottom: '25px' },
    ];
    return ledCoordinates.map((coord, i) => (
      <div key={i} className="led-wrapper" style={{ 
        position: 'absolute',
        left: coord.left, 
        bottom: coord.bottom, 
        zIndex: coord.zindex !== undefined ? coord.zindex : (parseInt(coord.bottom) < 80 ? 29 : 9),
        opacity: imagesLoaded ? 1 : 0,
        transition: 'opacity 0.3s ease-in'
      }}>
        <img
          className={`led ${ledStates[i] ? 'led-on' : ''}`}
          src={ledStates[i] ? '/SVG/Map/Led_On.svg' : '/SVG/Map/Led.svg'}
        />
      </div>
    ));
  }, [ledStates, imagesLoaded]);

  return (
    <div className='App' id='map'>
      <div className='brg-container'>
        <div className='sky'>
          {clouds}
        </div>
        <div className='ground'></div>
      </div>
      <div className='foreground'>
        {spawnBuildings()}
        <div className='led-container'>
          {spawnLEDs()}
        </div>
      </div>
    </div>
  );
}

export default MapComponent;