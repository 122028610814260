import './App.scss';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Map from './components/Map';
import MapDetail from './components/MapDetail';
import Recipe from './components/Recipe';

function AppComponent({ title, backgroundColor }) {
  const [time, setTime] = useState('00:00:00');
  const [count, setCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');
      setTime(`${hours}:${minutes}:${seconds}`);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const incrementCounter = () => {
    setCount(prevCount => prevCount + 1);
  };

  return (
    <div className="App" style={{ backgroundColor }}>
      <header className="App-header">
        <h1>{title}</h1>
        <div id="clock">{time}</div>
        <button onClick={incrementCounter}>Click me</button>
        <div id="counter">{count}</div>
      </header>
    </div>
  );
}

function RootComponent() {
  return (
      <div>
        <nav>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/map">Map</a></li>
            <li><a href="/recipe/arduino-gamecontroller/1">Arduino Gamecontroller Step 1</a></li>
            <li><a href="/recipe/arduino-gamecontroller/2">Arduino Gamecontroller Step 2</a></li>
            <li><a href="/recipe/arduino-gamecontroller/3">Arduino Gamecontroller Step 3</a></li>
            <li><a href="/recipe/arduino-gamecontroller/4">Arduino Gamecontroller Step 4</a></li>
            <li><a href="/recipe/arduino-gamecontroller/5">Arduino Gamecontroller Step 4</a></li>
          </ul>
        </nav>
      </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<RootComponent />} />
        <Route path="/map" element={<Map />} />
        <Route path="/map/:path" element={<MapDetail />} />
        <Route path="/recipe/:name/:task/" element={<Recipe />} />
      </Routes>
    </Router>
  );
}

export default App;