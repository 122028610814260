import React, { useState, useEffect } from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { arduinoLight, vs } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const Code = ({ filePath }) => {
    const [codeContent, setCodeContent] = useState('');

    useEffect(() => {
        const fetchCode = async () => {
            try {
                const response = await fetch(filePath);
                if (!response.ok) {
                    throw new Error('Failed to fetch the code file');
                }
                const text = await response.text();
                setCodeContent(text);
            } catch (error) {
                console.error('Error fetching code:', error);
                setCodeContent('Error loading code.');
            }
        };

        fetchCode();
    }, [filePath]);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(codeContent)
            .then(() => {
                console.log('Code copied to clipboard');
                // Optionally, you can add some visual feedback here
            })
            .catch(err => {
                console.error('Failed to copy code: ', err);
            });
    };

    return (
        <div className="content code" style={{padding: 0}}>
            <SyntaxHighlighter className="code-container"  language='arduino' style={vs}>
                {codeContent}
            </SyntaxHighlighter>
            <button className='copy-button' onClick={copyToClipboard}>copy</button>
        </div>
    );
};

export default Code;