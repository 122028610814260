import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './MapDetail.scss';

function MapDetail() {
    const { path } = useParams();
    const [hoveredLink, setHoveredLink] = useState(null);
    const [contentMap, setContentMap] = useState(null);

    useEffect(() => {
        fetch('/Content/content-map.json')
            .then(response => response.json())
            .then(data => setContentMap(data))
            .catch(error => console.error('Error loading content-map.json:', error));
    }, []);

    function scroll(dir) {
        const contentInner = document.querySelector('.content-inner');
        if (contentInner) {
            if (dir === 'up') {
                contentInner.scrollBy({ top: -100, behavior: 'smooth' });
            } else {
                contentInner.scrollBy({ top: 100, behavior: 'smooth' });
            }
        }
    }

    if (!contentMap) {
        return <div>Loading...</div>;
    }

    const content = contentMap.map[path];
    const backgroundImage = `/SVG/Map/MapDetail_Background_${content.background}.svg`;
    const mascotImage = `/SVG/Map/Mascot_${content.mascot}.svg`;

    return (
        <div className='map-detail'>
            <div className="brg" style={{ backgroundImage: `url(${backgroundImage})`, backgroundColor: `${content.backround_color}`, backgroundSize: '200%', backgroundPosition: 'center 70%', backgroundRepeat: 'no-repeat' }}></div>
            <div className="frg">
                <button className='btn-back' onClick={() => { window.location.href = '/map'; }}>
                    <img src={'/SVG/Map/Btn_Back.svg'} alt="Back" />
                </button>

                <img className='window' src={'/SVG/Map/clouds_window.gif'} alt="Clouds" />

                <img className='mascot' src={mascotImage} alt="Mascot" />

                <div className="content">
                    <figure style={{ backgroundImage: `url('/SVG/Map/Speechbubble_Large.svg')`, backgroundSize: 'fit', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}></figure>
                    <div className="content-inner">
                        <h1>{content.title}</h1>
                        <p dangerouslySetInnerHTML={{ __html: content.content }}></p>
                    </div>
                    <div className="arrows">
                        <img src={'/SVG/Map/Arrow_Up.svg'} alt="Arrow Up" onClick={() => { scroll('up'); }} />
                        <img src={'/SVG/Map/Arrow_Down.svg'} alt="Arrow Down" onClick={() => { scroll('down'); }} />
                    </div>
                </div>

                <div className='link-items'>
                    {content.links && content.links.map((link, index) => (
                        <div key={index} className="link-item-wrapper"
                             onMouseEnter={() => setHoveredLink(index)}
                             onMouseLeave={() => setHoveredLink(null)}>
                            <a href={link.link} target='_blank' rel='noopener noreferrer'>
                                <img src={`/SVG/Map/${link.icon}.svg`} alt={link.title} width={link.width} style={{ bottom: link.bottom }} />
                            </a>
                            <div className={`link-tooltip ${hoveredLink === index ? 'visible' : ''}`}>
                                {link.title}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default MapDetail;